.about-us-content-dark-section {
  background: #000;
  // background-image: url("../../../../public/images/about-us/color-bg.jpg");
  // background-position: center;
  // background-size: cover;
  // background-repeat: no-repeat;
  // padding: 50px 0px;

  .left-images-dv {
    height: 100%;
    img{
      height: 100%;
    }
  }
  .right-about-content-dv {
    padding: 50px;
    color: #fff;
    .about-heading-dv {
      h5 {
        font-weight: 600;
        font-size: 22px;
      }
    }
    .about-content {
      p {
        text-align: justify;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content-dark-section {
    .right-about-content-dv {
      padding: 50px;
      height: 200px;
      overflow-y: scroll;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #fff;
      }
      &::-webkit-scrollbar-track {
        border: none;
        background-color: transparent;
      }
    }
  }
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .about-us-content-dark-section {
    .right-about-content-dv {
      padding: 10px;
    }
  }
}
