.pillars-of-eapro-section {
  background: #cccccc5c;
  background-image: url("../../../../public/images/bg-banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0px;

  .piller-view-all-btn {
    a {
      font-weight: 700;
      text-decoration: none;
      color: #000;
    }
  }

  .specialization-heading-dv {
    h5 {
      font-weight: 700;
      font-size: 22px;
    }
  }

  .piller-card-row {
    margin: 40px 0px 20px 0px;

    .piller-card-parent {
      border: 1px solid #d3a100;
      border-radius: 90px 90px 77px 77px;
      background-color: #fff;

      .piller-img {
        width: 109%;
        margin-top: -26px;
        margin-left: -8px;
        margin-right: 0;

        img {
          width: 100%;
          aspect-ratio: 2/2;
          object-fit: contain;
        }
      }

      .piller-content {
        padding: 20px 10px;
        height: 265px;

        h4 {
          color: #d3a100;
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        p {
          font-size: 11px;
          display: -webkit-box;
          -webkit-line-clamp: 7;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .piller-read-more {
          text-decoration: none;
          color: #000;
          font-size: 14px;
        }
      }
    }

    .piller-items {
      padding: 40px 10px;
    }
  }
}
