@media only screen and (min-width: 1201px) {}

@media only screen and (min-width: 993px) and (max-width: 1200px) {}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .banner-section {
    margin-bottom: 30px;

    .carousel {
      .carousel-control-prev-icon {
        // margin-right: 55%;
      }

      .carousel-control-next-icon {
        // margin-left: 55%;
      }

      .carousel-indicators {
        margin-bottom: -20px;
      }

      .carousel-inner {
        .carousel-item {
          height: 100% !important;

          .carousel-caption {
            position: absolute;
            top: 50%;
            bottom: 0;
            transform: translate(-50%, -50%);
            left: 30%;
            width: 50%;
            text-align: left;
            padding: 10px 0px;

            .learn-more-btn {
              margin-top: 10px;

              a {
                background-color: #ffc300;
                font-size: 12px;
                font-weight: 700;
                text-decoration: none;
                color: #000;
                padding: 6px 12px;
                border-radius: 10px;
              }
            }

            .banner-heading {
              font-size: 18px;
              font-family: "Orbitron", sans-serif;
              line-height: 20px;
            }

            .para-name {
              font-size: 12px;
              font-style: italic;
              margin-bottom: 0px;
              width: 100%;

              p {
                margin-bottom: 0px !important;
              }
            }
          }
        }
      }
    }

    .carousel-width {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 321px) and (max-width: 767px) {
  .banner-section {
    margin-bottom: 30px;

    .carousel {
      .carousel-control-prev-icon {
        // margin-right: 55%;
      }

      .carousel-control-next-icon {
        // margin-left: 55%;
      }

      .carousel-indicators {
        margin-bottom: -20px;
      }

      .carousel-inner {
        .carousel-item {
          height: 100% !important;

          video {
            object-fit: cover;
          }

          img {
            height: 200px;
          }

          .carousel-caption {
            // position: absolute;
            // top: 50%;
            // transform: translate(-50%, -80%);
            // left: 32%;
            // width: 55%;
            // text-align: left;
            // padding: 10px 0px;

            .carousel-width {
              width: 100%;
            }

            .learn-more-btn {
              margin-top: 5%;

              a {
                background-color: #ffc300;
                font-size: 12px;
                font-weight: 700;
                text-decoration: none;
                color: #000;
                padding: 4px 12px;
                border-radius: 10px;
              }
            }

            .banner-heading {
              font-size: 10px;
              font-family: "Orbitron", sans-serif;
              line-height: 15px;
            }

            .para-name {
              font-size: 8px;
              font-style: italic;
              margin-bottom: 0px;
              width: 100%;


            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 100px) and (max-width: 320px) {
  .banner-section {
    margin-bottom: 30px;

    .carousel {
      .carousel-control-prev-icon {
        // margin-right: 55%;
      }

      .carousel-control-next-icon {
        // margin-left: 55%;
      }

      .carousel-indicators {
        margin-bottom: -20px;
      }

      .carousel-inner {
        .carousel-item {
          height: 100% !important;

          video {
            height: 250px;
            object-fit: contain;

          }

          img {

            height: 250px;

          }

          .carousel-caption {
            position: absolute;
            top: 42%;
            transform: translate(-50%, -50%);
            left: 30%;
            width: 50%;
            text-align: left;
            padding: 10px 0px;

            .carousel-width {
              width: 100%;
            }

            .learn-more-btn {
              margin-top: 10px;

              a {
                background-color: #ffc300;
                font-size: 12px;
                font-weight: 700;
                text-decoration: none;
                color: #000;
                padding: 4px 10px;
                border-radius: 10px;
              }
            }

            .banner-heading {
              font-size: 12px;
              font-family: "Orbitron", sans-serif;
              line-height: 22px;
            }

            .para-name {
              font-size: 10px;
              font-style: italic;
              margin-bottom: 0px;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 100px) and (max-width: 266px) {
  .banner-section {
    .learn-more-btn {
      margin-top: 10px;

      a {

        font-size: 8px !important;

      }
    }
  }

}

@media only screen and (min-width: 993px) and (max-width: 1107px) {
  .banner-section {



    .banner-heading {
      font-size: 30px !important;
      line-height: 35px !important;
    }

    .para-name {
      font-size: 12px;


    }

  }

}

// @media only screen and (min-width: 321px) and (max-width: 500px) {
//   .banner-section {
//     .carousel-caption {

//       top: 42% !important;


//       .learn-more-btn {


//         a {

//           font-size: 8px !important;


//           i {
//             font-size: 10px !important;
//           }
//         }
//       }



//       .para-name p {
//         margin-bottom: 0px !important;
//       }
//     }
//   }

// }