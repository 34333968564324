.purchase-amc-page-3-content-section {
    background: #000;
    background-image: url("../../../../public/images/help-and-support/color-bg.jpg");
    background-position: center;
    background-size: 100% 100%;
    background-color: #F1F0F0;
    background-repeat: no-repeat;
    padding: 50px 0px;
    background-attachment: fixed;

    .purchase-amc-product-details-parent {
        text-align: left;

        .product-details-heading {
            p {
                font-weight: 700;
            }
        }

        table {
            tbody {
                tr {
                    td {
                        font-size: 15px;
                        padding: 3px;
                    }
                }
            }
        }
    }

    .help-support-heading {
        h5 {
            font-weight: 700;
        }
    }

    .select-plan-start-row {
        margin-top: 70px;
        margin-bottom: 20px;

        .select-you-plan-parent {
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            height: 365px;
            background: rgb(255, 255, 255);
            background: linear-gradient(180deg, rgba(255, 255, 255, 1) 58%, rgba(204, 204, 204, 1) 100%);

            .plan-inner-name-img {
                position: relative;
                top: -27px;

                .plan-inner-heading {
                    position: absolute;
                    bottom: 25px;
                    left: 0;
                    right: 0;

                    p {
                        font-weight: 700;
                        font-size: 22px;
                    }
                }
            }

            .plan-price-div {
                h1 {
                    color: #FBC306;
                    margin-bottom: 0px;
                }
            }

            .plan-price-duration {
                margin: 30px 0px;

                p {
                    color: #757474;
                }
            }

            .plan-buy-now-btn {
                margin: 20px 0px;

                a {
                    background-color: #000;
                    color: #fff;
                    border-radius: 5px;
                    padding: 5px 20px;
                    font-size: 14px;
                    text-decoration: none;
                }
            }
        }
    }


    .help-support-content-dv {
        margin: 20px 0px;
    }


    .help-and-support-section-inner {
        // background: #cccccc5c;
        // background-image: url("../../../../public/images/help-support-bg.png");
        // background-position: center;
        // background-size: cover;
        // background-repeat: no-repeat;
        padding: 50px 0px;
        background-color: transparent;

        .heading-dv {
            h5 {
                font-weight: 700;
                color: #000;
                font-size: 22px;
            }
        }

        .help-support-inner-section {
            .first-card-parent {
                background-color: #fff;
                border: 1px solid #ccc;
                border-radius: 5px;
                padding: 10px 20px;
                margin: 20px 0px;

                .mobile-tag {
                    text-align: left;

                    p {
                        margin-bottom: 0px;
                        margin: 10px 0px;

                        a {
                            text-decoration: none;
                            color: #000;
                            font-weight: 700;
                        }

                        img {
                            width: 24px;
                            margin-right: 10px;
                        }
                    }
                }
            }

            .second-side-card-parent {
                background-color: #fff;
                border: 1px solid #ccc;
                border-radius: 5px;
                padding: 10px 20px;
                margin: 20px 0px;

                .mobile-tag {
                    text-align: left;

                    p {
                        margin-bottom: 0px;
                        margin: 10px 0px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-content: center;
                        justify-content: space-between;
                        align-items: center;

                        a {
                            text-decoration: none;
                            color: #000;
                            font-weight: 700;
                        }

                        img {
                            width: 24px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }

}

.help-and-support-section-inner {
    // background: #cccccc5c;
    // background-image: url("../../../../public/images/help-support-bg.png");
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;
    padding: 50px 0px;
    background-color: transparent;

    .heading-dv {
        h5 {
            font-weight: 700;
            color: #000;
            font-size: 22px;
        }
    }

    .help-support-inner-section {
        .first-card-parent {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 10px 20px;
            margin: 20px 0px;

            .mobile-tag {
                text-align: left;

                p {
                    margin-bottom: 0px;
                    margin: 10px 0px;

                    a {
                        text-decoration: none;
                        color: #000;
                        font-weight: 700;
                    }

                    img {
                        width: 24px;
                        margin-right: 10px;
                    }
                }
            }
        }

        .second-side-card-parent {
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 10px 20px;
            margin: 20px 0px;

            .mobile-tag {
                text-align: left;

                p {
                    margin-bottom: 0px;
                    margin: 10px 0px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-content: center;
                    justify-content: space-between;
                    align-items: center;

                    a {
                        text-decoration: none;
                        color: #000;
                        font-weight: 700;
                    }

                    img {
                        width: 24px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}