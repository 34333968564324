// .business-division-banner-section {
//     background: #000;
//     background-image: url("../../../../public/images/business-division/banner-bg.png");
//     background-position: center;
//     background-size: 100% 100%;
//     background-color: #F1F0F0;
//     background-repeat: no-repeat;
//     padding: 40px 0px 210px 0px;
//     height: 400px;
//     .business-heading-top{
//         h1{
//             font-weight: 700;
//             font-size: 60px;
//         }
//     }
//     .business-heading {
//         position: relative;
//         text-align: center;

//         img {
//             height: 60px;
//         }

//         h1 {
//             font-weight: 700;
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             transform: translate(-50%, -50%);
//             font-size: 55px;

//         }
//     }

// }

.business-division-banner-section {
    background: #000;
    // background-image: url("../../../../public/images/help-and-support/banner-bg.jpg");
    background-position: center;
    background-size: cover;
    background-color: #F1F0F0;
    background-repeat: no-repeat;
    .business-heading-top{
        h1{
            font-weight: 700;
            font-size: 60px;
        }
    }
    .business-heading {
        position: relative;
        text-align: center;

        img {
            height: 60px;
        }

        h1 {
            font-weight: 700;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 55px;

        }
    }
    // padding: 80px 0px;
    // height: 400px;
    // .business-heading {
    //     position: relative;
    //     text-align: center;
    //     img {
    //         height: 100px;
    //         width: 50%;
    //     }

    //     .business-banner-heading {
    //         position: absolute;
    //         top: 50%;
    //         left: 0%;
    //         transform: translate(0%, -50%);
    //         width: 100%;

    //         h1 {
    //             font-weight: 700;
    //             font-size: 40px;
    //         }

    //         h5 {
    //             font-weight: 700;
    //             width: 45%;
    //         }
    //     }

    // }

    .banner-top-img-bg-rel {
        position: relative;

        img.banner-img-tag {
            width: 100%;
        }

        .banner-top-content-absol {
            position: absolute;
            top: 15%;
            left: 0%;
            transform: translate(0%, -15%);
            width: 100%;
        }
    }
}