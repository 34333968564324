@media only screen and (min-width: 768px) and (max-width: 992px) {
  .banner-section {
    margin-bottom: 30px;
  }
  .banner-section .carousel .carousel-indicators {
    margin-bottom: -20px;
  }
  .banner-section .carousel .carousel-inner .carousel-item {
    height: 100% !important;
  }
  .banner-section .carousel .carousel-inner .carousel-item .carousel-caption {
    position: absolute;
    top: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    left: 30%;
    width: 50%;
    text-align: left;
    padding: 10px 0px;
  }
  .banner-section .carousel .carousel-inner .carousel-item .carousel-caption .learn-more-btn {
    margin-top: 10px;
  }
  .banner-section .carousel .carousel-inner .carousel-item .carousel-caption .learn-more-btn a {
    background-color: #ffc300;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    color: #000;
    padding: 6px 12px;
    border-radius: 10px;
  }
  .banner-section .carousel .carousel-inner .carousel-item .carousel-caption .banner-heading {
    font-size: 18px;
    font-family: "Orbitron", sans-serif;
    line-height: 20px;
  }
  .banner-section .carousel .carousel-inner .carousel-item .carousel-caption .para-name {
    font-size: 12px;
    font-style: italic;
    margin-bottom: 0px;
    width: 100%;
  }
  .banner-section .carousel .carousel-inner .carousel-item .carousel-caption .para-name p {
    margin-bottom: 0px !important;
  }
  .banner-section .carousel-width {
    width: 100%;
  }
}
@media only screen and (min-width: 321px) and (max-width: 767px) {
  .banner-section {
    margin-bottom: 30px;
  }
  .banner-section .carousel .carousel-indicators {
    margin-bottom: -20px;
  }
  .banner-section .carousel .carousel-inner .carousel-item {
    height: 100% !important;
  }
  .banner-section .carousel .carousel-inner .carousel-item video {
    -o-object-fit: cover;
       object-fit: cover;
  }
  .banner-section .carousel .carousel-inner .carousel-item img {
    height: 200px;
  }
  .banner-section .carousel .carousel-inner .carousel-item .carousel-caption .carousel-width {
    width: 100%;
  }
  .banner-section .carousel .carousel-inner .carousel-item .carousel-caption .learn-more-btn {
    margin-top: 5%;
  }
  .banner-section .carousel .carousel-inner .carousel-item .carousel-caption .learn-more-btn a {
    background-color: #ffc300;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    color: #000;
    padding: 4px 12px;
    border-radius: 10px;
  }
  .banner-section .carousel .carousel-inner .carousel-item .carousel-caption .banner-heading {
    font-size: 10px;
    font-family: "Orbitron", sans-serif;
    line-height: 15px;
  }
  .banner-section .carousel .carousel-inner .carousel-item .carousel-caption .para-name {
    font-size: 8px;
    font-style: italic;
    margin-bottom: 0px;
    width: 100%;
  }
}
@media only screen and (min-width: 100px) and (max-width: 320px) {
  .banner-section {
    margin-bottom: 30px;
  }
  .banner-section .carousel .carousel-indicators {
    margin-bottom: -20px;
  }
  .banner-section .carousel .carousel-inner .carousel-item {
    height: 100% !important;
  }
  .banner-section .carousel .carousel-inner .carousel-item video {
    height: 250px;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .banner-section .carousel .carousel-inner .carousel-item img {
    height: 250px;
  }
  .banner-section .carousel .carousel-inner .carousel-item .carousel-caption {
    position: absolute;
    top: 42%;
    transform: translate(-50%, -50%);
    left: 30%;
    width: 50%;
    text-align: left;
    padding: 10px 0px;
  }
  .banner-section .carousel .carousel-inner .carousel-item .carousel-caption .carousel-width {
    width: 100%;
  }
  .banner-section .carousel .carousel-inner .carousel-item .carousel-caption .learn-more-btn {
    margin-top: 10px;
  }
  .banner-section .carousel .carousel-inner .carousel-item .carousel-caption .learn-more-btn a {
    background-color: #ffc300;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    color: #000;
    padding: 4px 10px;
    border-radius: 10px;
  }
  .banner-section .carousel .carousel-inner .carousel-item .carousel-caption .banner-heading {
    font-size: 12px;
    font-family: "Orbitron", sans-serif;
    line-height: 22px;
  }
  .banner-section .carousel .carousel-inner .carousel-item .carousel-caption .para-name {
    font-size: 10px;
    font-style: italic;
    margin-bottom: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;
  }
}
@media only screen and (min-width: 100px) and (max-width: 266px) {
  .banner-section .learn-more-btn {
    margin-top: 10px;
  }
  .banner-section .learn-more-btn a {
    font-size: 8px !important;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1107px) {
  .banner-section .banner-heading {
    font-size: 30px !important;
    line-height: 35px !important;
  }
  .banner-section .para-name {
    font-size: 12px;
  }
}/*# sourceMappingURL=ResponsiveBanner.css.map */