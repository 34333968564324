.why-eapro-section{
    background: #cccccc5c;
    background-image: url("../../../../public/images/why-bg-banner.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 50px;
    // padding: 50px 0px;
    // height: 100vh;
    display: flex;
    align-items: center;
    background-attachment: fixed;
    .why-heading-dv{
        h5{
            font-weight: 700;
            font-size: 22px;
        }
    }
    .para-content{
    }
    .counter-row{
        .card-box-counter{
            background-color: #000;
            padding: 30px 20px;
            color: #fff;
            h4{
                margin-bottom: 0px;
                font-weight: 700;
            }
            p{
                margin-bottom: 0px;
                font-size: 15px;
            }
        }
        .card-box-counter.bg-yellow{
            background-color: #FFC300;
            color: #000;
        }
    }
}