.navbar-sec .navbar {
  background-color: #000;
}
.navbar-sec .navbar .navbar-brand img {
  width: 175px;
}
.navbar-sec .navbar .navbar-collapse .navbar-nav-center-cus {
  padding-right: 150px;
}
.navbar-sec .navbar .navbar-collapse .navbar-nav-center-cus li.nav-item {
  margin: 0px 6px;
}
.navbar-sec .navbar .navbar-collapse .navbar-nav li.nav-item a.nav-link {
  color: #fff;
  font-size: 15px;
}
.navbar-sec .navbar .navbar-collapse .navbar-nav li.nav-item a.nav-link.dropdown-toggle {
  display: flex;
  align-items: center;
}
.navbar-sec .navbar .navbar-collapse .navbar-nav li.nav-item a.nav-link.dropdown-toggle i {
  margin-left: 10px;
  font-size: 22px;
}
.navbar-sec .navbar .navbar-collapse .navbar-nav li.nav-item a.nav-link.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  border: none;
}
.navbar-sec .navbar .navbar-collapse .navbar-nav li.user-icon a.nav-link img {
  width: 16px;
}
.navbar-sec .navbar .navbar-collapse .navbar-nav li.cart-icon a.nav-link img {
  width: 20px;
}

.navbar-second-section {
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
  position: sticky;
  top: 0;
  z-index: 99;
}
.navbar-second-section .navbar-collapse .navbar-nav-second-ul .search-item .search-inner-dv ul.search-results-ul {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  list-style-type: none;
  text-align: left;
  padding-left: 0px;
}
.navbar-second-section .navbar-collapse .navbar-nav-second-ul .search-item .search-inner-dv ul.search-results-ul li {
  border-bottom: 1px solid #ccc;
  padding: 5px;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
  width: 200px;
  word-wrap: break-word;
}
.navbar-second-section .navbar-collapse .navbar-nav li.nav-item {
  padding: 0px 2px;
  font-weight: 600;
}
.navbar-second-section .navbar-collapse .navbar-nav li.nav-item .dropdown-menu .dropdown-item {
  font-size: 14px;
}
.navbar-second-section .navbar-collapse .navbar-nav li.nav-item a.nav-link {
  font-size: 14px;
  padding: 0px 10px;
  color: #000;
}
.navbar-second-section .navbar-collapse .navbar-nav li.nav-item a.nav-link.dropdown-toggle {
  display: flex;
  align-items: center;
}
.navbar-second-section .navbar-collapse .navbar-nav li.nav-item a.nav-link.dropdown-toggle i {
  margin-left: 10px;
  font-size: 22px;
}
.navbar-second-section .navbar-collapse .navbar-nav li.nav-item a.nav-link.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  border: none;
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.power-product-ul {
  display: none;
  transition: all 0.5s;
  opacity: 0;
  width: 600px;
  padding: 10px 10px;
  box-shadow: 0 4px 8px rgba(0, 48, 151, 0.1215686275);
  border-radius: 2px;
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.power-product-ul .megamenu-inner-parent-dv h4 {
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
  padding: 5px 0px;
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.power-product-ul .megamenu-inner-parent-dv ul {
  padding: 0px;
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.power-product-ul .megamenu-inner-parent-dv ul li {
  list-style: none;
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.power-product-ul .megamenu-inner-parent-dv ul li .dropdown-item.active,
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.power-product-ul .megamenu-inner-parent-dv ul li .dropdown-item:active {
  background-color: transparent;
  color: #000;
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.power-product-ul .megamenu-inner-parent-dv ul li a.dropdown-item {
  padding-left: 0px;
  display: inline-block;
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.power-product-ul .megamenu-inner-parent-dv ul li a.dropdown-item:after {
  display: block;
  content: "";
  border-bottom: solid 1px #000000;
  transform: scaleX(0);
  transform-origin: 0% 50%;
  transition: transform 250ms ease-in-out;
  width: 100%;
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.power-product-ul .megamenu-inner-parent-dv ul li a.dropdown-item:hover:after {
  transform: scaleX(1);
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.power-product-ul .megamenu-inner-parent-dv ul li a.dropdown-item:hover {
  background-color: transparent;
  color: #000;
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.power-product-ul .megamenu-inner-parent-dv ul li a.dropdown-item:focus, .navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.power-product-ul .megamenu-inner-parent-dv ul li a.dropdown-item:active {
  background-color: transparent;
  color: #000;
}
.navbar-second-section .navbar-collapse .navbar-nav .power-product-a:hover .dropdown-menu.power-product-ul {
  display: block;
  animation: smooth-appear 1s ease forwards;
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
  transform: translate(-45%, 0%);
  width: 60vw;
  border-radius: 5px;
  left: 45%;
  /* Custom scrollbar styling for Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}
.navbar-second-section .navbar-collapse .navbar-nav .power-product-a:hover .dropdown-menu.power-product-ul ::-webkit-scrollbar {
  width: 0px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}
.navbar-second-section .navbar-collapse .navbar-nav .power-product-a:hover .dropdown-menu.power-product-ul ::-webkit-scrollbar {
  width: 5px;
  /* Width of the scrollbar */
}
.navbar-second-section .navbar-collapse .navbar-nav .power-product-a:hover .dropdown-menu.power-product-ul ::-webkit-scrollbar-track {
  background: transparent;
  /* Background of the scrollbar track */
}
.navbar-second-section .navbar-collapse .navbar-nav .power-product-a:hover .dropdown-menu.power-product-ul ::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar thumb */
  border-radius: 5px;
  /* Round corners of the scrollbar thumb */
}
.navbar-second-section .navbar-collapse .navbar-nav .power-product-a:hover .dropdown-menu.power-product-ul ::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scrollbar thumb on hover */
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.solar-product-ul {
  display: none;
  transition: all 0.5s;
  opacity: 0;
  width: 600px;
  padding: 10px 10px;
  box-shadow: 0 4px 8px rgba(0, 48, 151, 0.1215686275);
  border-radius: 2px;
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.solar-product-ul .megamenu-inner-parent-dv h4 {
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
  padding: 5px 0px;
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.solar-product-ul .megamenu-inner-parent-dv ul {
  padding: 0px;
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.solar-product-ul .megamenu-inner-parent-dv ul li {
  list-style: none;
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.solar-product-ul .megamenu-inner-parent-dv ul li .dropdown-item.active,
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.solar-product-ul .megamenu-inner-parent-dv ul li .dropdown-item:active {
  background-color: transparent;
  color: #000;
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.solar-product-ul .megamenu-inner-parent-dv ul li a.dropdown-item {
  padding-left: 0px;
  display: inline-block;
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.solar-product-ul .megamenu-inner-parent-dv ul li a.dropdown-item:after {
  display: block;
  content: "";
  border-bottom: solid 1px #000000;
  transform: scaleX(0);
  transform-origin: 0% 50%;
  transition: transform 250ms ease-in-out;
  width: 100%;
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.solar-product-ul .megamenu-inner-parent-dv ul li a.dropdown-item:hover:after {
  transform: scaleX(1);
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.solar-product-ul .megamenu-inner-parent-dv ul li a.dropdown-item:hover {
  background-color: transparent;
  color: #000;
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.solar-product-ul .megamenu-inner-parent-dv ul li a.dropdown-item:focus, .navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.solar-product-ul .megamenu-inner-parent-dv ul li a.dropdown-item:active {
  background-color: transparent;
  color: #000;
}
.navbar-second-section .navbar-collapse .navbar-nav .power-product-a:hover .dropdown-menu.solar-product-ul {
  display: block;
  animation: smooth-appear 1s ease forwards;
}
@keyframes smooth-appear {
  to {
    opacity: 1;
  }
}
.navbar-second-section .navbar-collapse .navbar-nav .dropdown-menu.solar-product-ul {
  display: none;
  transition: all 0.5s;
  opacity: 0;
}
.navbar-second-section .navbar-collapse .navbar-nav .solar-product-a:hover .dropdown-menu.solar-product-ul {
  display: block;
  animation: smooth-appear 1s ease forwards;
}
.navbar-second-section .navbar-collapse .navbar-nav li.nav-item.search-item {
  margin-left: 0px;
  color: #000;
}
.navbar-second-section .navbar-collapse .navbar-nav li.nav-item.search-item .search-inner-dv {
  position: relative;
}
.navbar-second-section .navbar-collapse .navbar-nav li.nav-item.search-item .search-inner-dv input {
  border-radius: 20px;
  box-shadow: none;
  border: 1px solid #000;
  padding-left: 35px;
  width: 196px;
}
.navbar-second-section .navbar-collapse .navbar-nav li.nav-item.search-item .search-inner-dv input::-moz-placeholder {
  color: #000;
  font-weight: 600;
  font-size: 14px;
}
.navbar-second-section .navbar-collapse .navbar-nav li.nav-item.search-item .search-inner-dv input::placeholder {
  color: #000;
  font-weight: 600;
  font-size: 14px;
}
.navbar-second-section .navbar-collapse .navbar-nav li.nav-item.search-item .search-inner-dv .search-icon-a-tag {
  position: absolute;
  top: 0;
  left: 12px;
  color: #000;
}

.mobile-navbar-section {
  position: sticky;
  top: 0;
  z-index: 9999;
}
.mobile-navbar-section header.active:before {
  transition: opacity 0.2s, visibility 0ms;
  z-index: 5;
  opacity: 1;
  visibility: visible;
}
.mobile-navbar-section header.active nav.slide-out-menu {
  transform: translateZ(0);
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s, visibility 0ms, transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
.mobile-navbar-section header nav.navbar {
  padding: 10px;
  background-color: #000;
  display: block;
}
.mobile-navbar-section header nav.navbar .navbar-brand-img {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}
.mobile-navbar-section header nav.navbar .navbar-brand-img .menu-toggle-btn {
  border: none;
  box-shadow: none;
  padding: 8px 10px;
  background: #fff;
}
.mobile-navbar-section header nav.navbar .navbar-brand-img .logo-cus img {
  width: 135px;
}
.mobile-navbar-section header nav.navbar .navbar-brand-img .cart-search-icon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.mobile-navbar-section header nav.navbar .navbar-brand-img .cart-search-icon .nav-link {
  margin-right: 10px;
}
.mobile-navbar-section header nav.navbar .navbar-brand-img .cart-search-icon .nav-link:first-child {
  margin-right: 15px;
}
.mobile-navbar-section header nav.navbar .navbar-brand-img .cart-search-icon .nav-link img {
  width: 18px;
}
.mobile-navbar-section header nav.navbar .navbar-brand-img .cart-search-icon .nav-link i {
  font-size: 18px;
  margin-top: 5px;
}
.mobile-navbar-section header nav.navbar .search-input-with-icon {
  position: relative;
  margin-top: 10px;
}
.mobile-navbar-section header nav.navbar .search-input-with-icon .search-results-ul {
  list-style: none;
  height: 60vh;
  overflow-y: scroll;
  text-align: left;
}
.mobile-navbar-section header nav.navbar .search-input-with-icon .search-results-ul li {
  border-bottom: 1px solid #fff;
  padding: 5px 0px;
}
.mobile-navbar-section header nav.navbar .search-input-with-icon input.search-input {
  width: 100%;
  padding: 8px 5px 8px 40px;
}
.mobile-navbar-section header nav.navbar .search-input-with-icon .icon-dv {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(3%, -50%);
}
.mobile-navbar-section header nav.navbar .search-input-with-icon .icon-dv button {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.mobile-navbar-section header nav.navbar .search-input-with-icon .search-results-mobile-ul {
  text-align: left;
}
.mobile-navbar-section .mobile-sidebar-logo-dv {
  background-color: #000;
  padding: 5px 10px;
  margin-bottom: 15px;
}
.mobile-navbar-section .mobile-sidebar-logo-dv .navbar-brand-img .logo-cus {
  width: 150px;
}
.mobile-navbar-section .mobile-sidebar-logo-dv .navbar-brand-img .cart-search-icon a {
  background-color: #fff;
  color: #000;
  text-decoration: none;
  padding: 8px 8px;
}
.mobile-navbar-section header nav.slide-out-menu {
  z-index: 9000;
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  opacity: 0;
  visibility: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
  transition: opacity 0.2s, visibility 0ms linear 0.2s, transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transform: translate3d(-290px, 0, 0);
  height: 100vh;
}
.mobile-navbar-section header nav.slide-out-menu div.menu-panels {
  overflow: hidden;
}
.mobile-navbar-section header nav.slide-out-menu div.menu-panels .start-second-menu-aria {
  margin-top: 20px;
  height: calc(100vh - 315px);
  overflow-y: auto;
}
.mobile-navbar-section header nav.slide-out-menu div.menu-panels .start-second-menu-aria ul li {
  font-size: 14px;
  background-color: white;
  border-bottom: 1px solid transparent;
}
.mobile-navbar-section header nav.slide-out-menu div.menu-panels .start-second-menu-aria ul li a {
  text-align: left;
}
.mobile-navbar-section header nav.slide-out-menu div.menu-panels .start-second-menu-aria ul li a .menu-img-icon {
  width: 23px;
  margin-right: 10px;
}
.mobile-navbar-section header nav.slide-out-menu div.menu-panels .menu-link {
  position: relative;
  text-align: left;
  width: 100%;
  display: block;
  padding: 14px 20px;
  background: transparent;
  margin: 0;
  border: none;
}
.mobile-navbar-section header nav.slide-out-menu div.menu-panels .menu-link .menu-img-icon {
  width: 23px;
  margin-right: 10px;
}
.mobile-navbar-section header nav.slide-out-menu div.menu-panels .menu-link svg {
  position: absolute;
  top: 50%;
  margin-top: -7px;
  height: 14px;
}
.mobile-navbar-section header nav.slide-out-menu div.menu-panels .menu-link svg.arrow-right {
  right: 10px;
}
.mobile-navbar-section header nav.slide-out-menu div.menu-panels .menu-link svg.arrow-left {
  left: 10px;
}
.mobile-navbar-section header nav.slide-out-menu div.menu-panels .menu-link.menu-header {
  text-align: center;
}
.mobile-navbar-section header nav.slide-out-menu div.menu-panel.is-active .menu-link.menu-header {
  font-size: 18px;
  font-weight: 700;
  color: #000;
}
.mobile-navbar-section header nav.slide-out-menu div.menu-panels ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.mobile-navbar-section header nav.slide-out-menu div.menu-panels ul li {
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #e5e5e5;
}
.mobile-navbar-section header nav.slide-out-menu div.menu-panels ul li a {
  width: 100%;
  display: block;
  padding: 14px 20px;
  color: #000000;
  text-decoration: none;
}
.mobile-navbar-section header nav.slide-out-menu div.menu-panel {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
  background: #fff;
  z-index: 0;
  transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1), visibility 0s linear 0.4s;
  visibility: hidden;
  transform: translateX(-290px);
}
.mobile-navbar-section header nav.slide-out-menu div.menu-panel.is-active {
  visibility: visible;
  transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1), visibility 0s;
  transform: translateX(0);
  z-index: 1;
}/*# sourceMappingURL=Navbar.css.map */