.help-and-support-section {
  background: rgba(204, 204, 204, 0.3607843137);
  background-image: url("../../../../public/images/help-support-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0px;
}
.help-and-support-section .heading-dv h5 {
  font-weight: 700;
  color: #000;
  font-size: 22px;
}
.help-and-support-section .help-support-inner-section .first-card-parent {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0px;
}
.help-and-support-section .help-support-inner-section .first-card-parent .mobile-tag {
  text-align: left;
}
.help-and-support-section .help-support-inner-section .first-card-parent .mobile-tag p {
  margin-bottom: 0px;
  margin: 10px 0px;
}
.help-and-support-section .help-support-inner-section .first-card-parent .mobile-tag p a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.help-and-support-section .help-support-inner-section .first-card-parent .mobile-tag p img {
  width: 24px;
  margin-right: 10px;
}
.help-and-support-section .help-support-inner-section .second-side-card-parent {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 20px;
  margin: 8px 0px;
}
.help-and-support-section .help-support-inner-section .second-side-card-parent .mobile-tag {
  text-align: left;
}
.help-and-support-section .help-support-inner-section .second-side-card-parent .mobile-tag p {
  margin-bottom: 0px;
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.help-and-support-section .help-support-inner-section .second-side-card-parent .mobile-tag p a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}
.help-and-support-section .help-support-inner-section .second-side-card-parent .mobile-tag p img {
  width: 24px;
  margin-right: 10px;
}/*# sourceMappingURL=HelpAndSupport.css.map */